import React from "react";
import { Route, Routes } from "react-router-dom";
import { CameraPage } from "./pages/CameraPage.js";
import { MessagePage } from "./pages/MessagePage.js";
import { OverlayPage } from "./pages/OverlayPage.js";
import { SelectionPage } from "./pages/SelectionPage.js";
import HomePage from "./pages/HomePage.js";
import ExamplePage from "./pages/ExamplePage.js";

export const App = () => {
  return (
    <Routes>
      <Route path={'/'} element={<HomePage />} />
      <Route path={'/camera'} element={<CameraPage />} />
      <Route path={'/example'} element={<ExamplePage />} />
      <Route path={'overlay/:sessionId'} element={<OverlayPage />} />
      <Route path={'selection/:sessionId'} element={<SelectionPage />} />
      <Route path={'message/:sessionId'} element={<MessagePage />} />
    </Routes>
  );
};
