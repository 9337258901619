import React from 'react';

export const ScrollPageBase = ({ title, onBack, children, footerButtonText, onFooterButtonClick, gold = false, showFooter = true }) => {
  return (
    <div className="flex flex-col h-screen">
      <header className="fixed top-0 left-0 right-0 flex items-center justify-between p-4 bg-blue-600 text-white z-50">
        <div className="flex-none">
          <button className="w-12 h-12 flex items-center justify-center bg-blue-500 rounded-full" onClick={onBack}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6" strokeWidth={4}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        </div>
        <h1 className="text-2xl mx-4 text-center flex-grow">{title}</h1>
        <div className="flex-none">
          <button className="w-12 h-12 flex items-center justify-center bg-blue-500 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className='text-white fill-current' height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
              <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
            </svg>
          </button>
        </div>
      </header>

      <main className="flex-grow overflow-y-auto pt-16 pb-20">
        {children}
      </main>

      {showFooter && <footer className="fixed bottom-0 left-0 right-0 bg-blue-600 p-4 z-50">
        <button
          className={`w-full py-4 text-xl ${gold ? "bg-yellow-400 text-black" : "bg-blue-500 text-white"} rounded-lg`}
          onClick={onFooterButtonClick}
        >
          {footerButtonText}
        </button>
      </footer>}
    </div>
  );
};
