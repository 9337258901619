import React, { useEffect, useRef, useState } from "react";
import { Camera } from "react-camera-pro";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import { uploadImage } from "../api";

export const CameraPage = () => {
    const camera = useRef(null);
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const takePhoto = async () => {
        if (!image) {
            const capturedImage = camera.current.takePhoto();
            setImage(capturedImage);
        }
    };

    useEffect(() => {
        if (image) {
            setLoading(true);
            const imageFile = dataURLToFile(image, 'captured-image.jpg');
            uploadImage(imageFile, (data) => {
                setLoading(false);
                navigate("/overlay/" + data.id, data);
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    return (
        <div onClick={takePhoto}>
            {loading && <Loading />}
            {image ? (
                <img src={image} alt="Taken seconds ago." style={{ width: "100%" }} />
            ) : (
                <Camera ref={camera} facingMode="environment" />
            )}
        </div>
    );
};

function dataURLToFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([new Blob([u8arr], {type:mime})], filename, {type: mime});
}
