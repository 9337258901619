import React from 'react';

const ExamplePage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-start p-8">
      <div className="w-full flex justify-center">
        <img 
          src="/images/logo_large.png" 
          alt="Menu Buddy Logo" 
          className="w-24 h-24"
        />
      </div>
      <div className="text-center mt-8">
        <h1 className="text-3xl font-bold">Menu Buddy Example</h1>
        <p className="text-lg mt-2">
          Use the information below to test Menu Buddy without needing to be in a restaurant
        </p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between items-start mt-12 w-full max-w-4xl">
        <div className="flex flex-col items-center w-full lg:w-1/2 p-4">
          <img 
            src="/images/menu.jpeg" 
            alt="Example" 
            className="w-full max-w-sm rounded shadow"
          />
          <a href="/images/menu.jpeg" download="example-menu.jpeg" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
              Download this image
          </a>
        </div>
        <div className="w-full lg:w-1/2 p-4">
          <h2 className="text-xl font-semibold">Instructions</h2>
          <ul className="list-disc list-inside mt-4 space-y-2">
            <li>Download this sample menu image.</li>
            <li>Visit the <a href='/' className='text-blue-500'>Menu Buddy homepage</a>.</li>
            <li>Select upload image and upload the image you just downloaded (or another image of a menu if you have one!).</li>
            <li>Pick out some items as if you were to order and click the yellow "Ready" button.</li>
            <li>View the "card" generated for a cashier.</li>
            <li>Ensure you browser has microphone access, then click the microphone button and ask "Would you like a side with that?"</li>
            <li>See what Menu Buddy does!</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ExamplePage;
