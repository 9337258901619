const serverUrl = process.env.REACT_APP_SERVER_URL;

export const uploadImage = async (image, sessionCallback, errorCallback = null) => {
    const formData = new FormData();
    formData.append('image', image);
    fetch(serverUrl + '/api/upload', {
        method: 'PUT',
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => sessionCallback(data))
        .catch((error) => {
            if (errorCallback) {
                errorCallback(error);
            }
        });
};

export const uploadAudio = async (sessionId, audioUrl, sessionCallback, errorCallback = null) => {
    const audioBlob = await fetch(audioUrl).then((r) => r.blob());
    const audioFile = new File([audioBlob], "audiofile.mp3", {
      type: "audio/mpeg",
    });
    const formData = new FormData();
    formData.append('audio', audioFile);
    fetch(serverUrl + `/api/audio/session/${sessionId}`, {
        method: 'POST',
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => sessionCallback(data))
        .catch((error) => {
            if (errorCallback) {
                errorCallback(error);
            }
        });
};

export const fetchSession = async (sessionId, sessionCallback, compileMessage = false, sectionId = null, errorCallback = null) => {

    let params = '';

    if (compileMessage) {
        params += 'compile_message=true';
    }

    if (sectionId !== null) {
        params += params.length > 0 ? '&' : '';
        params += `expand_section=${sectionId}`;
    }

    fetch(serverUrl + `/api/get/session/${sessionId}?${params}`)
        .then((response) => response.json())
        .then((data) => sessionCallback(data))
        .catch((error) => {
            if (errorCallback) {
                errorCallback(error);
            }
        });
};

export const updateSelection = async (sessionId, selection, errorCallback = null) => {
    updateSession(sessionId, { selection }, errorCallback);
};

export const updateMessage = async (sessionId, message, errorCallback = null) => {
    updateSession(sessionId, { message }, errorCallback);
};

const updateSession = async (sessionId, body, errorCallback = null) => {
    fetch(serverUrl + `/api/update/session/${sessionId}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: new Headers({'content-type': 'application/json'})
    }).catch((error) => {
        if (errorCallback) {
            errorCallback(error);
        }
    });
};