import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import { fetchSession, updateMessage, uploadAudio } from "../api";
import RecordingButton from "../components/RecordingButton";
import { ScrollPageBase } from "../components/ScrollPageBase";

export const MessagePage = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState("");
  const [questionData, setQuestionData] = useState(null);
  const [options, setOptions] = useState({});
  const textAreaRef = useRef(null);

  const { sessionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (message == null) {
      setLoading(true);
      fetchSession(sessionId, (session) => {
        setLoading(false);
        setMessage(session.message);
      }, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editing && textAreaRef.current) {
        textAreaRef.current.focus();
        textAreaRef.current.setSelectionRange(editedMessage.length, editedMessage.length);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const onClickBack = () => {
    navigate("/selection/" + sessionId.toString());
  };

  const toggleEditMode = () => {
    if (editing) {
      updateMessage(sessionId, editedMessage);
      setMessage(editedMessage);
    } else {
      setEditedMessage(message);
    }
    setEditing(!editing);
  };

  const handleAudioRecorded = (audioFile) => {
    setLoading(true);
    uploadAudio(sessionId, audioFile, (question) => {
      setOptions({});
      setQuestionData(question);
      setLoading(false);
    });
  };

  const handleOptionClick = (index) => {
    let localOptions = { ...options };
    localOptions[index.toString()] = !localOptions[index.toString()];
    setOptions(localOptions);
  };

  if (questionData) {
    return (<ScrollPageBase title={questionData.question} onBack={() => setQuestionData(null)} showFooter={false} >
      <div className="space-y-4 p-4">
      {questionData.options.map((option, index) => (
                <div onClick={() => handleOptionClick(index)} key={index} className="flex items-center justify-between p-4 bg-blue-100 rounded-lg cursor-pointer">
                    <span className="text-2xl font-semibold pl-4">
                        {option}
                    </span>
                    <div className={`w-12 h-12 min-w-12 min-h-12 flex-shrink-0 flex items-center justify-center rounded-full cursor-pointer border-2 border-black ${options[index.toString()] ? 'bg-black' : 'bg-white'}`}>
                    </div>
                </div>
            ))}
            </div>
    </ScrollPageBase>);
  }

  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="bg-white rounded-3xl w-11/12 h-5/6 flex flex-col justify-between items-center p-8">
        {loading && <Loading />}
        <div className="flex-1 flex items-center justify-center text-center" onClick={!editing ? toggleEditMode : undefined} >
          {editing ? (
            <textarea ref={textAreaRef} className="w-full text-5xl md:text-7xl lg:text-8xl font-bold leading-none text-center bg-transparent outline-none resize-none" value={editedMessage} onChange={(e) => setEditedMessage(e.target.value)} />
          ) : (
            <p className="text-5xl md:text-7xl lg:text-8xl font-bold leading-none">
              {message}
            </p>
          )}
        </div>
        <div className="flex justify-center space-x-6 mb-6">
          <button onClick={onClickBack} className="w-16 h-16 bg-gray-400 text-white rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"/>
            </svg>
          </button>
          <button onClick={toggleEditMode} className={`w-16 h-16 rounded-full flex items-center justify-center ${editing ? "bg-green-500" : "bg-gray-400"} text-white`}>
            {editing ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"/>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" width="24px" viewBox="0 -960 960 960" fill="#ffffff">
                <path d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"/>
              </svg>
            )}
          </button>
          {!editing && <RecordingButton onAudioRecorded={handleAudioRecorded} />}
        </div>
      </div>
    </div>
  );
};

export default MessagePage;
