import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import { uploadImage } from "../api";

export const HomePage = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg')) {
          setLoading(true);
          uploadImage(file, (session) => {
            setLoading(false);
            navigate("/overlay/" + session.id, session);
          });
        } else {
          console.error('Please select a .jpg or .jpeg file.');
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-200">
            {loading && <Loading />}
            <div className="bg-white rounded-3xl w-11/12 h-5/6 flex flex-col items-center justify-center">
                <img src="/images/logo_large.png" alt="Menu Buddy Large Logo" className="w-2/3 md:w-1/2 max-w-xs" />
                <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-4 w-full">
                    <input type="file" id="file-upload" accept=".jpg, .jpeg" style={{ display: 'none' }} onChange={handleFileChange}/>
                    <div onClick={() => document.getElementById("file-upload").click()} className="flex flex-col cursor-pointer items-center justify-center aspect-square mt-4 w-3/4 sm:w-1/ md:w-2/5 lg:w-1/4 max-w-[150px] border-4 border-blue-700 bg-blue-200 hover:bg-blue-300 text-black rounded-md">
                        <span className="text-center text-xl md:text-2xl lg:text-3xl">Upload an image</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 h-10 md:h-14 lg:h-16 w-10 md:w-14 lg:w-16" viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                        </svg>
                    </div>
                    <div onClick={() => navigate("/camera")} className="flex flex-col cursor-pointer items-center justify-center aspect-square mt-4 w-3/4 md:w-2/5 lg:w-1/4 max-w-[150px] border-4 border-red-700 bg-red-200 hover:bg-red-300 text-black rounded-md order-first lg:order-none">
                        <span className="text-center text-xl md:text-2xl lg:text-3xl">Go to camera</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 h-10 md:h-14 lg:h-16 w-10 md:w-14 lg:w-16" viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"/>
                        </svg>
                    </div>
                    <div onClick={toggleModal} className="flex flex-col cursor-pointer items-center justify-center aspect-square mt-4 w-3/4 md:w-2/5 lg:w-1/4 max-w-[150px] border-4 border-green-700 bg-green-200 hover:bg-green-300 text-black rounded-md">
                        <span className="text-center text-xl md:text-2xl lg:text-3xl">More info</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="mt-2 h-10 md:h-14 lg:h-16 w-10 md:w-14 lg:w-16" viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                        </svg>
                    </div>
                </div>
            </div>

            {isModalOpen && (
 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" role="dialog" aria-modal="true" aria-labelledby="modal-title" onKeyDown={(e) => e.key === 'Escape' && toggleModal()}>
   <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-lg outline-none">
       <h2 id="modal-title" className="text-3xl mb-6 text-gray-800 font-semibold">About Menu Buddy</h2>
       <ul className="list-disc list-inside space-y-3 text-lg leading-relaxed text-gray-700">
           <li>Menu Buddy helps speech-impaired individuals order in restaurants and coffee shops.</li>
           <li>Ensure your browser allows camera and microphone access before using.</li>
           <li>If you choose to use your camera, point it at any menu and tap the screen.</li>
           <li>Once your order is complete, click the "READY" button to convert your order to a card.</li>
           <li>The microphone button on the card page can record interactions with restaurant staff and transcribe them for you.</li>
       </ul>
       <button onClick={toggleModal} className="mt-6 px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white text-lg font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75" aria-label="Close about Menu Buddy modal">
           Close
       </button>
   </div>
</div>

            )}
        </div>
    );
};

export default HomePage;
