import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ScrollPageBase } from "../components/ScrollPageBase";
import { fetchSession, updateSelection } from "../api";
import { Loading } from "../components/Loading";

export const SelectionPage = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { sessionId } = useParams();

    const sectionId = location.state ? location.state.sectionId : null;

    const [stage, setStage] = useState(sectionId !== null ? Stage.SECTION : Stage.ORDER);
    const [itemId, setItemId] = useState(null);
    const [context, setContext] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selection, setSelection] = useState(null);
    const [options, setOptions] = useState({});

    useEffect(() => {
        // Fetch if we are missing data OR if we need to lazy-load the options for the items in this section.
        let fresh = context === null || selection === null;
        if (fresh || (sectionId !== null && context && context[sectionId] && !context[sectionId].expanded)) {
            if (fresh) {
                setLoading(true);
            }
            fetchSession(sessionId, (session) => {
                setContext(session.context);
                setSelection(session.selection);
                setLoading(false);
            }, false, sectionId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, sectionId]);

    if (!sessionId) {
        navigate('/camera');
        return null;
    }
  
    const handleBack = () => {
        switch (stage) {
            case Stage.ORDER: sectionId != null ? setStage(Stage.SECTION) : navigate("/overlay/" + sessionId.toString()); break;
            case Stage.SECTION: navigate("/overlay/" + sessionId.toString()); break;
            case Stage.OPTIONS: setStage(Stage.SECTION); break;
            default: break;
        }
    };
  
    const handleFooterButtonClick = () => {
      if (stage === Stage.SECTION) {
        setStage(Stage.ORDER);
      } else if (stage === Stage.ORDER) {
        navigate("/message/" + sessionId.toString());
      } else if (stage === Stage.OPTIONS) {
        let newSelection = [...selection];
        let item = context[sectionId].items[itemId];
        item.options = Object.keys(options).filter((key) => options[key]).map((key) => item.options[parseInt(key)]);
        newSelection.push(item);
        updateSelection(sessionId, newSelection);
        setSelection(newSelection);
        setStage(Stage.ORDER);
      }
    };

    const section = sectionId !== null && sectionId > -1 && context ? context[sectionId] : null

    const title = stage !== Stage.ORDER && section ? (stage === Stage.OPTIONS && itemId !== null ? section.items[itemId].name : section.name ? section.name : "Menu") : "Your Order"

    const handleItemClick = (idx) => {
        if (stage === Stage.SECTION) {
            if (section && section.items[idx]) {
                if (section.items[idx].options.length > 0) {
                    setItemId(idx);
                    setOptions({});
                    setStage(Stage.OPTIONS);
                } else {
                    let newSelection = [...selection];
                    newSelection.push(section.items[idx]);
                    updateSelection(sessionId, newSelection);
                    setSelection(newSelection);
                    setStage(Stage.ORDER);
                }
            }
        } else if (stage === Stage.ORDER) {
            let newSelection = [...selection];
            if (idx < newSelection.length) {
                newSelection.splice(idx, 1);
                updateSelection(sessionId, newSelection);
                setSelection(newSelection);
            }
        } else if (stage === Stage.OPTIONS) {
            let localOptions = { ...options };
            localOptions[idx.toString()] = !localOptions[idx.toString()];
            setOptions(localOptions);
        }
    }

    return (
      <ScrollPageBase title={title} onBack={handleBack} footerButtonText={stage.footerText} onFooterButtonClick={handleFooterButtonClick} gold={stage === Stage.ORDER}>
        {loading && <Loading />}
        <div className="space-y-4 p-4 mt-12">
          {stage === Stage.SECTION && section && section.items.map((item, index) => (
            <div key={index} onClick={() => handleItemClick(index)} className="flex items-center justify-between p-4 bg-blue-100 rounded-lg cursor-pointer">
                <span className="text-2xl font-semibold pl-4">
                    {item.name}
                </span>
                <div className="w-12 h-12 flex items-center justify-center bg-green-500 rounded-full">
                    {item.options.length > 0 ? 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6" strokeWidth={4} >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                        </svg> : 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"  fill="#000000" stroke="currentColor" className="w-6 h-6" strokeWidth={6} >
                            <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                        </svg>
                    }
                </div>
            </div>
          ))}
          {stage === Stage.ORDER && selection && selection.map((selected, index) => (
            <div key={index}  className="flex items-center justify-between p-4 bg-blue-100 rounded-lg cursor-pointer">
                <span className="text-2xl font-semibold pl-4">
                    {selected.name}
                </span>
                <div onClick={() => handleItemClick(index)} className="w-12 h-12  min-w-12 min-h-12 flex-shrink-0 flex items-center justify-center bg-red-500 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#00000"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                </div>
            </div>
          ))}
            {stage === Stage.OPTIONS && section && section.items && itemId !== null && section.items[itemId] && section.items[itemId].options.map((option, index) => (
                <div onClick={() => handleItemClick(index)} key={index} className="flex items-center justify-between p-4 bg-blue-100 rounded-lg cursor-pointer">
                    <span className="text-2xl font-semibold pl-4">
                        {option}
                    </span>
                    <div className={`w-12 h-12 min-w-12 min-h-12 flex-shrink-0 flex items-center justify-center rounded-full cursor-pointer border-2 border-black ${options[index.toString()] ? 'bg-black' : 'bg-white'}`}>
                    </div>
                </div>
            ))}
        </div>
      </ScrollPageBase>
    );
  };

const Stage = Object.freeze({
    SECTION:   {
        name: "section",
        footerText: "View order"
    },
    OPTIONS:  {
        name: "options",
        footerText: "Add to order"
    },
    ORDER: {
        name: "order",
        footerText: "Ready"
    }
});
