import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../components/Loading";
import { fetchSession } from "../api";

export const OverlayPage = (starterSession = null) => {

    const navigate = useNavigate();
    const { sessionId } = useParams();
    const [coords, setCoords] = useState([]);
    const [session, setSession] = useState(starterSession);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        fetchSession(sessionId, (session) => {
            setCoords(session.context);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAreaClick = (idx) => {
        navigate("/selection/" + sessionId.toString(), { state: { sectionId: idx } });
    };

    useEffect(() => {
        if (!session || Object.keys(session).length === 0) {
            setLoading(true);
            fetchSession(sessionId, (data) => {
                setSession(data);
                setLoading(false);
            });
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {loading && <Loading />}
            <img src={session.public_image_url} alt="Taken seconds ago, with overlay." style={{ width: "100vw", height: "100vh" }} />
            {
                coords.map((coord_object, index) => {
                    const coord_set = coord_object.box;
                    const topLeft = { y: coord_set[0], x: coord_set[1] };
                    const bottomRight = { y: coord_set[2], x: coord_set[3] };
                    const buttonStyle = {
                        position: 'absolute',
                        left: `${(topLeft.x / 1000) * 100}%`,
                        top: `${(topLeft.y / 1000) * 100}%`,
                        width: `${((bottomRight.x - topLeft.x) / 1000) * 100}%`,
                        height: `${((bottomRight.y - topLeft.y) / 1000) * 100}%`,
                        border: '4px solid blue',
                        backgroundColor: 'rgba(0, 0, 255, 0.2)',
                    };
                    return (<button className="rounded-lg" style={buttonStyle} onClick={() => handleAreaClick(index)}></button>);
                })
            }
        </div>
    );
};